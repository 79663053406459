import './boot';

import select2 from 'select2';
select2();

import { Calendar } from 'fullcalendar';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import itLocale from '@fullcalendar/core/locales/it';

import 'jquery-ui/dist/jquery-ui';

import jBob from './jbob';

import.meta.glob([
	'../images/**',
]);

$(document).ready(function() {
	let jbob = new jBob();
	jbob.init({
		fixBootstrap: ['Toast'],
		initFunction: function(container) {
			container.closest('.sortable-table tbody').sortable('refresh');

			$('.search-users', container).each(function() {
				if ($(this).closest('tfoot').length == 0) {
					$(this).select2({
						data: [],
						theme: "bootstrap-5",
						ajax: {
							url: '/utenti/search',
							dataType: 'JSON'
						}
					});
				}
			});

			$('.preview-media', container).each(function() {
				if ($(this).closest('tfoot').length == 0) {
					$(this).find('input:file').change(function() {
						var input = this;

						if (input.files && input.files[0]) {
							let file = input.files[0];

							let reader = new FileReader();
							reader.onload = function (e) {
								let blobURL = URL.createObjectURL(file);
								let preview = null;

								if (file.type.startsWith('image')) {
									preview = $('<img>');
								}
								else if (file.type.startsWith('video')) {
									preview = $('<video autoplay loop muted>');
								}

								preview.addClass('img-fluid');
								preview.attr('src', blobURL);
								$(input).closest('.preview-media').find('.preview-media-slot').empty().append(preview);
							}

							reader.readAsDataURL(input.files[0]);
						}
					});
				}
			});

			$('.sortable-table tbody', container).sortable({
				items: '> tr',
				handle: '> td .sorting-handle',
				helper: 'clone',
			});

			$('.dynamic-comment', container).submit(function(e) {
				e.preventDefault();

				let form = $(this);
				form.prop('inert', true);

				$.ajax({
					method: form.attr('method'),
					url: form.attr('action'),
					data: form.serialize(),
					dataType: 'HTML',
					success: function(data) {
						form.closest('.card').before(data);
						form.find('textarea').val('');
						form.prop('inert', false);
					},
					error: function() {
						form.prop('inert', false);
					}
				});
			});

			$('#posts-calendar', container).each(function() {
				const calendarEl = document.getElementById('posts-calendar');

				const calendar = new Calendar(calendarEl, {
					plugins: [
						bootstrap5Plugin,
					],
					locale: itLocale,
					themeSystem: 'bootstrap5',
					initialView: 'dayGridMonth',
					events: function(info, successCallback, failureCallback) {
						$.ajax({
							url: '/clienti/tutti/contenuti',
							method: 'GET',
							dataType: 'JSON',
							data: {
								start: info.start.valueOf(),
								end: info.end.valueOf(),
							},
							success: function(data) {
								successCallback(data);
							}
						});
					}
				})

				calendar.render();
			});
		},
	});

	$('#instant_feedback').toast('show');
});

/*
 * jBob - Opinionated utilities for jQuery and Bootstrap
 * Copyright (C) 2021  Roberto Guido
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

class jBob {
	init(options) {
		var defaults = {
			initFunction: function() {},
			customFormActions: {},
			fixBootstrap: [],
		};

		this.mainOptions = $.extend({}, defaults, options || {});
		this.fixBootstrap(['Modal', 'Popover'].concat(this.mainOptions.fixBootstrap));

		this.onceInit();
		this.initElements($('body'));
	}

	/*
	    This function has been get directly from Bootstrap, and is intended to
	    init related jQuery plugins. Within certain environments (e.g Vite
	    build) this is not properly called on load (due jQuery is not updated to
	    the latest non-sense JS "best pratices" defined by people having nothing
	    to do all day long, and enjoying to continuosly break stuffs), so we
	    enforce the initialization here.
	    With no mercy, nor elegance,  but to get things done.
	*/
	defineJQueryPlugin(plugin) {
		const name = plugin.NAME;
		if (name in $.fn == false) {
			const JQUERY_NO_CONFLICT = $.fn[name];
			$.fn[name] = plugin.jQueryInterface;
			$.fn[name].Constructor = plugin;
			$.fn[name].noConflict = () => {
				$.fn[name] = JQUERY_NO_CONFLICT;
				return plugin.jQueryInterface;
			}
		}
	}

	fixBootstrap(plugins) {
		if (window.bootstrap) {
			plugins.forEach((plug) => {
				this.defineJQueryPlugin(window.bootstrap[plug]);
			});
		}
	}

	assignIDs(selectors, container) {
		selectors.forEach(function(selector, index) {
			$(selector, container).not('[id]').each(function() {
				$(this).attr('id', Math.random().toString(36).substring(2));
			});
		});
	}

	makeSpinner() {
		return '<div class="text-center"><div class="spinner-border" role="status"></div></div>';
	}

	formValidation(form) {
		if (form.hasClass('needs-validation')) {
			form.addClass('was-validated');
			return form.get(0).checkValidity();
		} else {
			return true;
		}
	}

	initElements(container) {
		/*
		    Init form validation
		*/
		$('form.needs-validation', container).each((index, node) => {
			$(node).on('submit', (e) => {
				let form = $(e.currentTarget);

				if (this.formValidation(form) == false) {
					e.preventDefault();
					e.stopPropagation();
				}
			});
		});

		/*
		    Init async modals
		*/
		this.assignIDs(['.async-modal'], container);

		$('.async-modal', container).click((e) => {
			e.preventDefault();
			let url = $(e.currentTarget).attr('data-modal-url') || $(e.currentTarget).attr('href');
			this.fetchRemoteModal(url);
		});

		/*
		    Init async accordions
		*/

		this.assignIDs(['.async-accordion'], container);

		$('.async-accordion > .accordion-collapse', container).each((index, node) => {
			this.handleAsyncAccordion(node);
		});

		/*
		    Init async tabs
		*/

		this.assignIDs(['.async-tab'], container);

		$('.async-tab', container).on('show.bs.tab', (e) => {
			e.stopPropagation();
			var pane_id = $(e.currentTarget).attr('data-bs-target');
			var pane = $(pane_id);
			var url = $(e.target).attr('data-tab-url');
			this.fetchNode(url, pane);
		}).on('hidden.bs.tab', (e) => {
			e.stopPropagation();
			var pane_id = $(e.currentTarget).attr('data-bs-target');
			$(pane_id).empty();
		});

		/*
		    Init async popovers
		*/

		$('.async-popover', container).on('show.bs.popover', (e) => {
			if (typeof $.data(e.target, 'dynamic-inited') == 'undefined') {
				$.data(e.target, 'dynamic-inited', {
					done: true
				});

				var pop = $(e.currentTarget);
				var url = pop.attr('data-contents-url');
				$.ajax({
					url: url,
					method: 'GET',
					dataType: 'HTML',

					success: function(data) {
						pop.attr('data-bs-content', data);
						pop.popover('dispose').popover('show');
					}
				});
			}
		});

		/*
			Init form actions
		*/

		$('form.with-actions', container).submit((e) => {
			e.preventDefault();
			var form = $(e.currentTarget);

			form.find('.is-invalid').removeClass('is-invalid');
			form.find('.invalid-feedback').remove();

			if (this.formValidation(form)) {
				$.ajax({
					method: form.attr('method'),
					url: form.attr('action'),
					data: this.serializeForm(form),
					success: (data) => {
						this.handleFormActions(form, data);
					},
					error: (xhr, ajaxOptions, thrownError) => {
						this.handleFormActionsError(form, xhr.responseText);
					}
				});
			}
		});

		/*
		    Init dynamic tables
		*/

		$('.dynamic-table', container).each(function() {
			$(this).find('> tfoot').find('input, select, textarea').addClass('skip-on-submit');
		}).on('click', '.add-row', (e) => {
			e.preventDefault();
			let btn = $(e.currentTarget);
			let table = btn.closest('table');
			let row = table.find('> tfoot tr').first().clone();
			row.find('.skip-on-submit').removeClass('skip-on-submit');
			btn.closest('tr').before(row);
			this.initElements(row);
		}).on('click', '.remove-row', function(e) {
			e.preventDefault();
			$(this).closest('tr').remove();
		});

		/*
		    Init modal forms
		*/

		$('.modal-form', container).on('submit', (e) => {
			e.preventDefault();
			var form = $(e.currentTarget);
			if (this.formValidation(form)) {
				$.ajax({
					method: form.attr('method'),
					url: form.attr('action'),
					data: this.serializeForm(form),
					success: function() {
						form.closest('.modal').modal('hide');
					}
				});
			}
		});

		this.mainOptions.initFunction(container);
	}

	handleFormActions(form, data) {
		var test = form.find('input[name=post-saved-refetch]');
		if (test.length != 0) {
			test.each((index, el) => {
				let selector = $(el).val();
				let box = $(selector);

				let url = box.attr('data-fetch-url');
				if (url == null) {
					url = $(el).attr('data-fetch-url');
				}

				this.fetchNode(url, box);
			});
		}

		var test = form.find('input[name=reload-portion]');
		if (test.length != 0) {
			test.each((index, el) => {
				var identifier = $(el).val();
				$(identifier).each((i, e) => {
					this.reloadNode($(e));
				});
			});
		}

		for (var custom in this.mainOptions.customFormActions) {
			var test = form.find('input[name=' + custom + ']');
			if (test.length != 0) {
				test.each((index, el) => {
					this.mainOptions.customFormActions[custom](form, data);
				});
			}
		}

		var test = form.find('input[name=void-form]');
		if (test.length != 0) {
			form.get(0).reset();
		}

		var test = form.find('input[name=close-modal]');
		if (test.length != 0) {
			var modal = form.parents('.modal');
			if (modal.length != 0) {
				modal.modal('hide');
			}
		}

		var test = form.find('input[name=close-all-modal]');
		if (test.length != 0) {
			$('.modal.fade.show').modal('hide');
		}

		var test = form.find('input[name=reload-whole-page]');
		if (test.length != 0) {
			location.reload();
		}
	}

	handleFormActionsError(form, data) {
		var test = form.find('input[name=check-validation-errors]');
		if (test.length != 0) {
			let payload = JSON.parse(data);
			if (payload.errors) {
				for (let attr in payload.errors) {
		            let input = form.find('[name=' + attr + ']');
					input.addClass('is-invalid');
					payload.errors[attr].forEach((message) => {
						input.closest('div').append('<span class="invalid-feedback">' + message + '</span>');
					});
				}
			}
		}
	}

	handleAsyncAccordion(accordion) {
		$(accordion).on('show.bs.collapse', (e) => {
			e.stopPropagation();
			var parent = $(e.currentTarget).closest('.async-accordion');
			var url = parent.attr('data-accordion-url');
			var body = parent.find('.accordion-body').first();
			this.fetchNode(url, body);
		});
	}

	fetchRemoteModal(url) {
		$.ajax({
			url: url,
			method: 'GET',
			dataType: 'HTML',
			success: (data) => {
				var d = $(data);
				this.initElements(d);
				d.addClass('delete-on-close').modal('show');
			}
		});
	}

	fetchNode(url, body) {
		body.empty().append(this.makeSpinner());

		$.ajax({
			url: url,
			method: 'GET',
			dataType: 'HTML',
			success: (data) => {
				var d = $(data);
				this.initElements(d);
				body.empty().append(d);
			}
		});
	}

	reloadNode(node) {
		var url = node.attr('data-reload-url');

		if (node.hasClass('modal')) {
			this.fetchRemoteModal(url);
			node.modal('hide');
		} else {
			$.ajax({
				url: url,
				method: 'GET',
				dataType: 'HTML',
				success: (data) => {
					var d = $(data);
					this.initElements(d);
					node.replaceWith(d);
				}
			});
		}
	}

	submitButton(form) {
		let ret = form.find('button[type=submit]');

		if (ret.length == 0) {
			let id = form.attr('id');
			if (id) {
				ret = $('button[type=submit][form=' + id + ']')
			}
		}

		return ret;
	}

	serializeForm(form) {
		return form.find(':not(.skip-on-submit)').serialize();
	}

	serializeFormData(form) {
		let ret = new FormData();

		$.each(form.find('input[type="file"]:not(.skip-on-submit)'), function(i, tag) {
			$.each($(tag)[0].files, function(i, file) {
				ret.append(tag.name, file);
			});
		});

		let values = form.find(':not(.skip-on-submit)').serializeArray();
		$.each(values, function(i, val) {
			ret.append(val.name, val.value);
		});

		return ret;
	}

	onceInit() {
		$('body').on('hidden.bs.modal', '.modal.delete-on-close', function() {
			$(this).remove()
		});
	}
}

export default jBob;
